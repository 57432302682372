<template>
  <section :class="{ 'fullScreen': !overlayAuthReady }">
    <div class="v-align">
      <div class="iframe">
        <iframe :src="pollUrl" allowtransparency="true" allowfullscreen="false"></iframe>
      </div>
      <div class="logo">
        <div class="inner">
          <img src="../assets/ford-gameday.png" />
          <img class="ford" src="../assets/ford.svg" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Poll',
  computed: {
    ...mapState([
      'overlayAuthReady'
    ]),
    ...mapGetters([
      'pollUrl'
    ])
  }
}
</script>

<style scoped lang="scss">
section {
  margin: 0 20px;
  height: 100%;
  border-radius: 30px 30px 0 0;
  background: rgba(16, 43, 80, 0.9);

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    // background: none;
    // background-image: linear-gradient(180deg, rgba(16,43,80,0) 0%, rgba(16,43,80,0.75) 35%, rgba(16,43,80,0.90) 75%, rgba(16,43,80,0.90) 100%);
  }

  > .v-align {
    display: table;
    border-collapse: collapse;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    > * {
      display: table-cell;
      vertical-align: middle;

      @media screen and (max-width: 1024px) {
        display: block;
        flex: 0 1 auto;
      }
    }
  }

  .iframe {
    position: relative;
    width: 99%;
    height: 175px;
    padding: 0 24px;

    @media screen and (max-width: 1024px) {
      width: 100%;
      flex: 1 1 auto;
      padding: 0;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;

    @media screen and (max-width: 1024px) {
    }
  }

  .logo {
    padding: 24px 0;

    @media screen and (max-width: 1024px) {
      padding: 0 24px;
      flex: 0 1 auto;
      width: calc(100% - 48px);
    }

    > .inner {
      padding: 0 24px;
      border-left: 1px solid rgba(255,255,255,0.2);

      @media screen and (max-width: 1024px) {
        padding: 12px;
        border-left: 0;
        border-top: 1px solid rgba(255,255,255,0.2);
      }
    }

    img {
      height: 130px;

      &.ford {
        display: none;
      }

      @media screen and (max-width: 1024px) {
        height: 40px;
        display: inline-block;
        vertical-align: middle;

        &.ford {
          display: inline-block;
          margin-left: 20px;
          height: 30px;
        }
      }
    }
  }

  &.fullScreen {
    background: none;
    margin: 0;

    .logo {
      display: none;
    }
  }
}
</style>