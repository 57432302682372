<template>
  <section class="video">
    <video ref="player" class="video-js vjs-fill vjs-big-play-centered" autoplay playsinline preload="metadata"></video>
    <span class="volume-control">
      <fa-icon
          :icon="['fas', volumeState? 'volume-mute' : 'volume-up']"
          size="lg"
          @click="toggleVolume(!volumeState)"
      />
    </span>
  </section>
</template>

<script>
import videojs from 'video.js';
import { mapMutations, mapState, mapActions } from 'vuex';
import 'video.js/dist/video-js.css';

export default {
  name: 'Video',
  data() {
    return {
      player: null
    }
  },
  computed: {
    ...mapState([
      'streamUrl',
      'volumeState'
    ])
  },
  methods: {
    ...mapMutations([
      'setPollId',
      'setPollHash',
      'setPollEnabled',
      'setProcessingTrigger',
      'showPoll',
      'setVolumeState'
    ]),
    ...mapActions([
      'processTrigger'
    ]),
    createAbsolutePath(assetPath) { 
      return new URL(assetPath, document.URL).toString();
    },
    toggleVolume(value) {
      this.setVolumeState(value);
      this.player.muted(value);
    }
  },
  watch: {
    player(value) {
      console.log("value", value, value.muted())
    }
  },
  mounted() {
    window.registerIVSTech(videojs);
    window.registerIVSQualityPlugin(videojs);

    this.player = videojs(this.$refs.player, {
      techOrder: ['AmazonIVS'],
      controlBar: {
        pictureInPictureToggle: false,
        playToggle: false,
        captionsButton: false,
        chaptersButton: false,            
        subtitlesButton: false,
        remainingTimeDisplay: false,
        fullscreenToggle: false,
        playbackRateMenuButton: false
      }
    }, () => {
      const excitemPlayer = this.player.getIVSPlayer();
      const player = this.player;
      const videoContainerEl = this.$refs.player;
      videoContainerEl.addEventListener("click", () => {
        if (this.player.paused()) {
          videoContainerEl.classList.remove("vjs-has-started");
        } else {
          videoContainerEl.classList.add("vjs-has-started");
        }
      });
      const PlayerState = this.player.getIVSEvents().PlayerState;
      excitemPlayer.addEventListener(PlayerState.PLAYING, () => {
        console.log("Player State - PLAYING");
        this.setVolumeState(player.muted());
        setTimeout(() =>
          console.log('Stream Latency', excitemPlayer.getLiveLatency())
        , 5000);
      });

      const PlayerEventType = this.player.getIVSEvents().PlayerEventType;
      excitemPlayer.addEventListener(PlayerEventType.ERROR, (err) => {
        console.warn("Player Event - ERROR: ", err);
        if (err.code === 404) {
          setTimeout(() => {
            this.player.src(this.streamUrl);
            this.player.load();
          }, 5000);
        }
      });

      excitemPlayer.addEventListener(PlayerEventType.TEXT_METADATA_CUE, async (cue) => {
        const metadataText = cue.text;
        try {
          const pollTrigger = JSON.parse(metadataText);
          this.processTrigger(pollTrigger);
        } catch (e) {
          console.log('Malformed metadata', e);
        }
      });

      excitemPlayer.addEventListener(PlayerState.IDLE, () => {
        const interval = this.player.setInterval(() => {
          if (!this.player.readyState()) {
            this.player.src(this.streamUrl);
            this.player.load();
          } else {
            this.player.clearInterval(interval);
          }
        }, 800);
      });
      setInterval(() =>
        console.log('sessionId', excitemPlayer.getSessionId())
      , 5000);

      this.player.enableIVSQualityPlugin();
      this.player.volume(0.5);
      this.player.src(this.streamUrl);
      this.setVolumeState(this.player.muted());
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style scoped lang="scss">
section.video {
  position: relative;
  width: 100%;
  height: 100%;
  background: grey;

  > .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

video {
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.vjs-control-bar {
  display: none !important;
}

video {
  object-fit: cover;
}

.video-js {
  background: grey;
}

.volume-control {
  color: white;
  position: absolute;
  top: 3px;
  right: 5px;
  font-size: 18px;
  cursor: pointer;
}
</style>
