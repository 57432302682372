import Vue from 'vue';
import App from './App.vue';
import store from './store';
import VueNativeSock from 'vue-native-websocket';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://d5bb5419c28e44c0bc169f6319cb4905@o109565.ingest.sentry.io/5641805",
  environment: process.env.NODE_ENV,
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracingOptions: {
    trackComponents: true
  },
  tracesSampleRate: 1.0
});


library.add(faVolumeMute);
library.add(faVolumeUp);
Vue.component('fa-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueNativeSock, process.env.VUE_APP_AWS_WEBSOCKET_URL, {
  store,
  format: 'json',
  reconnection: true,
  reconnectionDelay: 2000
});

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
