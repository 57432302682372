<template>
  <section>
    <div class="video">
      <Video />
    </div>
    <div class="poll" v-show="show" v-if="pollEnabled" :class="{ 'fullScreen': !overlayAuthReady }">
      <Poll />
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Poll from './Poll.vue';
import Video from './Video.vue';

export default {
  name: 'Main',
  components: {
    Poll,
    Video
  },
  computed: {
    ...mapState([
      'websocketConnected',
      'accountName',
      'pollEnabled',
      'overlayAuthReady',
      'processingTrigger',
      'show',
      'metadata',
      'streamingPoll'
    ])
  },
  methods: {
    ...mapMutations([
      'setOverlayAuth',
      'setPollEnabled',
      'showPoll'
    ]),
    ...mapActions([
      'getStreamingPoll',
      'getPresetTimedMetaData',
      'processTrigger'
    ]),
    async checkLateJoins() {
      try {
        await this.getPresetTimedMetaData();
        if (!(this.metadata || { steps: [] }).steps.length) return;
        const period = this.metadata.steps.reduce(function (accumulator, currentValue) {
          return accumulator + currentValue.period
        }, 0);
        await this.getStreamingPoll(period || 30);
        if (this.streamingPoll) {
          const pollTrigger = JSON.parse(JSON.stringify(this.metadata));
          pollTrigger.pollId = +this.streamingPoll.pollId;
          const timePassed = (new Date()).getTime() - this.streamingPoll.streamingStart;
          const pollPeriod = this.metadata.steps[0].period * 1000;
          const resultPeriod = this.metadata.steps[1].period * 1000;
          const leaderBoardPeriod = this.metadata.steps[2].period * 1000;
          const totalDuration = resultPeriod + pollPeriod + leaderBoardPeriod;
          if (timePassed > pollPeriod) {
            pollTrigger.steps[1].period = Math.round((resultPeriod + pollPeriod - timePassed ) / 1000) || 1;
            pollTrigger.steps.splice(0, 1);
          } else {
            pollTrigger.steps[0].period = Math.round((pollPeriod - timePassed) / 1000) || 1;
          }
          if (timePassed > pollPeriod + resultPeriod) {
            pollTrigger.steps.splice(0, 1);
            if (timePassed < totalDuration) {
              pollTrigger.steps[2].period = Math.round((totalDuration - timePassed ) / 1000) || 1
            } else {
              pollTrigger.steps.splice(0, 1);
            }
          }
          await this.processTrigger(pollTrigger);
        }
      } catch (e) {
        console.log('e', e);
      }
    },
  },
  watch: {
    websocketConnected(connected) {
      connected && this.$socket.sendObj({
        action: 'join',
        groupName: `/user:${this.accountName}`
      });
    },
  },
  async mounted() {
    try {
      window.addEventListener('message', (e) => {
        try {
          const {
            data: {
              auth,
              pollSettingsReady
            }
          } = e;
          if (this.overlayAuthReady && pollSettingsReady) {
            this.showPoll(true);
            this.setPollEnabled(true);
          } else if (typeof auth !== 'undefined') {
            this.setOverlayAuth(auth);
            this.setPollEnabled(!auth || this.processingTrigger);
            this.showPoll(!auth || this.processingTrigger);
          }
        } catch (e) {
          console.log('Failed to parse postMessage');
        }
      }, false);
    } catch (e) {
      console.log('Failed starting sequence');
    }
    await this.checkLateJoins();
  }
}
</script>

<style scoped lang="scss">
section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .poll {
    height: 175px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    @media screen and (max-width: 1024px) {
      height: 330px;
      width: 100%;
    }

    &.fullScreen {
      height: 100%;
    }
  }
}
</style>
