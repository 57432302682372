import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountName: process.env.VUE_APP_ACCOUNT_NAME,
    pollId: '',
    pollHash: '',
    pollEnabled: true,
    streamUrl: process.env.VUE_APP_STREAM_URL,
    page: 1,
    overlayAuthReady: false,
    processingTrigger: false,
    show: false,
    websocketConnected: false,
    volumeState: true,
    pageLoadDelay: 0,
    streamingPoll: null,
    metadata: null
  },
  getters: {
    pollUrl(state, next = true) {
      return `${process.env.VUE_APP_SWIFT_CDN_PLAY_URL_PREFIX}play/${state.accountName}${ next ? `/${state.pollId}` : ''}?group=bbl#${state.pollHash}`;
    },
    websocketConnected(state) {
      return state.websocketConnected;
    },
    layoutReady(state) {
      return state.show === true;
    },
    isIos() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
  },
  mutations: {
    setPollEnabled(state, enabled) {
      state.pollEnabled = enabled;
    },
    setPollId(state, pollId) {
      state.pollId = pollId;
    },
    setPollHash(state, hash) {
      state.pollHash = hash;
    },
    setOverlayAuth(state, ready) {
      state.overlayAuthReady = ready;
    },
    setProcessingTrigger(state, processing) {
      state.processingTrigger = processing;
    },
    showPoll(state, value) {
      state.show = value;
    },
    setStreamingPoll(state, data) {
      state.streamingPoll = data;
    },
    setMetadata(state, metadata) {
      state.metadata = metadata;
    },
    SOCKET_ONOPEN(state) {
      console.log('wss open');
      state.websocketConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      console.log('wss close');
      state.websocketConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error('WSS error', event);
    },
    SOCKET_RECONNECT(state, event) {
      console.log('WSS reconnect', event);
    },
    setVolumeState(state, value) {
      state.volumeState = value;
    }
  },
  actions: {
    stream({ getters, dispatch }, data) {
      try {
        const {
          payload
        } = data;
        if (getters.isIos) {
          dispatch('processTrigger', payload);
        }
      } catch (e) {
        console.log('Mailformed event data');
      }
    },
    delay(context, period) {
      return new Promise((y) => setTimeout(y, period * 1000));
    },
    isLayoutReady({ getters, dispatch }) {
      return new Promise((y, n) => {
        (async () => {
          let count = 100;
          while (!getters.layoutReady && count) {
            await dispatch('delay', 0.075);
            --count;
          }
          count ? y() : n();
        })();
      });
    },
    async getPresetTimedMetaData({ state, commit }) {
      try {
        const {
          data
        } = await axios.get(`${process.env.VUE_APP_SWIFT_CDN_PLAY_URL_PREFIX}api/v1/event/open/${state.accountName}/stream/1/metadata/preset/gamePlay`); // @todo hardcoded
        commit('setMetadata', data);
      } catch (e) {
        console.log('e', e);
      }
    },
    async getStreamingPoll({ state, commit }, duration) {
      try {
        const {
          data
        } = await axios.get(`${process.env.VUE_APP_VOTE_URL}/api/poll/streaming/${state.accountName}/duration/${duration}`);
        commit('setStreamingPoll', data);
      } catch (e) {
        console.log('e', e);
      }
    },
    async processTrigger({ commit, dispatch, state }, pollTrigger) {
      try {
        if (state.processingTrigger) return;
        commit('setProcessingTrigger', true);
        await dispatch('delay', 0);
        commit('setPollEnabled', true);
        for (let i = 0; i < pollTrigger.steps.length; i++) {
          commit('setPollId', pollTrigger.pollId);
          if (pollTrigger.steps[i].timer) {
            pollTrigger.steps[i].startTime = Date.now();
          }
          commit('setPollHash', JSON.stringify(pollTrigger.steps[i]));
          i === 0 && (await dispatch('isLayoutReady'));
          await dispatch('delay', pollTrigger.steps[i].period + state.pageLoadDelay);
        }
        state.overlayAuthReady && commit('setPollEnabled', false);
        state.overlayAuthReady && commit('showPoll', false);
        commit('setProcessingTrigger', false);
      } catch (e) {
        console.log('Malformed metadata', e);
      }
    }
  }
})
